<template>
<div class="col-md-4 login-sec">
  <h2>{{ $t('register') }}</h2>
  <form @submit.prevent="register" @keydown="form.onKeydown($event)" class="register-form">
    <div class="form-group">
      <label for="first_name" class="text-uppercase">{{ $t('first_name') }}</label>
      <input type="text" class="form-control" v-model="form.first_name" :class="{ 'is-invalid': form.errors.has('first_name') }">
      <has-error :form="form" field="first_name" />
    </div>
    <div class="form-group">
      <label for="last_name" class="text-uppercase">{{ $t('last_name') }}</label>
      <input type="text" class="form-control" v-model="form.last_name" :class="{ 'is-invalid': form.errors.has('last_name') }">
      <has-error :form="form" field="last_name" />
    </div>
    <div class="form-group">
      <label for="surname" class="text-uppercase">{{ $t('patronimic_name') }}</label>
      <input type="text" class="form-control" v-model="form.surname" :class="{ 'is-invalid': form.errors.has('surname') }">
      <has-error :form="form" field="surname" />
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1" class="text-uppercase">{{ $t('email') }}</label>
      <input type="text" class="form-control" v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }">
      <has-error :form="form" field="email" />
    </div>
    <div class="form-group">
      <label for="phone" class="text-uppercase">{{ $t('phone') }}</label>
      <input v-model="form.phone" v-mask="mask" class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }"/>
      <has-error :form="form" field="phone" />
    </div>
    <div class="form-group">
      <label for="card_number" class="text-uppercase">{{ $t('card_number') }}</label>
      <input type="text" class="form-control" v-model="form.card_number" :class="{ 'is-invalid': form.errors.has('card_number') }">
      <has-error :form="form" field="card_number" />
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1" class="text-uppercase">{{ $t('password') }}</label>
      <input type="password" class="form-control" v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }">
      <has-error :form="form" field="password" />
    </div>
    <div class="form-group">
      <label for="exampleInputPassword2" class="text-uppercase">{{ $t('password_confirmation') }}</label>
      <input type="password" class="form-control" v-model="form.password_confirmation" :class="{ 'is-invalid': form.errors.has('password_confirmation') }">
      <has-error :form="form" field="password_confirmation" />
    </div>
    <button type="submit" class="btn btn-login btn-primary float-right">{{ $t('register') }}</button>
    <router-link :to="{ name: 'login' }" class="small">
      {{ $t('login') }}
    </router-link>
  </form>
</div>
</template>
<script>
import Form from 'vform'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
export default {
  middleware: 'guest',
  layout: 'starter',
  metaInfo () {
    return { title: this.$t('register') }
  },
  data: () => ({
    mask: ['+38', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    form: new Form({
      first_name: '',
      last_name: '',
      surname: '',
      phone: '',
      card_number: '',
      email: '',
      password: '',
      password_confirmation: ''
    })
  }),
  methods: {
    ...mapActions({
      setToken: 'auth/saveToken',
      updateUser: 'auth/updateUser'
    }),
    async register () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await this.form.post(apiUrl + 'register')
      if (data && data.status) {
        Swal.fire({
          icon: 'info',
          title: 'Успішна реєстрація',
          text: 'Для підтвердження Е-мейл, вам відправлено лист з інструкцією.',
          reverseButtons: true,
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel')
        })
        this.$router.push({ name: 'login' })
      }
      this.$root.$refs.loading.finish()
    }
  }
}
</script>
